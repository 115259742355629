import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import Footer from '../components/footer'
import Navbar from '../components/navbar'
import Container from '../components/container'
import NewsArticle from '../components/newsArticle'

export default function news() {
    const navigation = [
        { name: 'На головну', href: '/', key: 50 },
    ]

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Новини</title>
                <meta name="theme-color" content="#53C82A" />
                <link rel="apple-touch-icon" href="logo.png"></link>
            </Helmet>
            <div className="bg-hero-image bg-no-repeat bg-cover bg-top">
                <Container>
                    <Navbar navigation={navigation} />
                </Container>
            </div>

            <Container>
                <div className="mt-8 lg:mt-24">
                    <h1 className="text-4xl text-center text-gray-900 tracking-tight font-bold sm:text-5xl md:text-6xl">
                        Новини
                    </h1>
                    <div className="my-12">
                        <div className="relative">
                            <StaticQuery
                                query={
                                    graphql`
                            {
                                allDataJson {
                                    nodes {
                                        siteNews {
                                            date
                                            title
                                            text    
                                            image {
                                                childImageSharp {
                                                  gatsbyImageData(
                                                    height: 240
                                                    transformOptions: {fit: COVER}
                                                    placeholder: DOMINANT_COLOR
                                                  )
                                                }
                                            }
                                            video
                                        }
                                    }
                                }
                            }
                            `
                                }
                                render={data => {
                                    const filteredData = data.allDataJson.nodes[1].siteNews ? data.allDataJson.nodes[1].siteNews : data.allDataJson.nodes[0].siteNews ? data.allDataJson.nodes[0].siteNews : data.allDataJson.nodes[2].siteNews

                                    return (
                                        <div>
                                            <div className="block mx-auto max-w-md sm:max-w-none">
                                                <div className=" grid gap-12 sm:grid-cols-2 lg:grid-cols-4 mb-12">
                                                    {filteredData.map((item, index) => <NewsArticle key={index} node={item} />)}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                }
                            />
                        </div>
                    </div>
                </div>
            </Container>

            <div className="bg-green-400">
                <Container>
                    <Footer />
                </Container>
            </div>
        </div>
    )
}
